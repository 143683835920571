const CartCommon = function(){
	const jsMessages = JSON.parse(document.getElementById('JsMessages').value);
	const cartId = document.getElementById("UserCart_CartID") ? document.getElementById("UserCart_CartID").value : "";
	const token = document.querySelectorAll('input[name=__RequestVerificationToken]')[0] ? document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value : "";

	const checkCartHasCoupon = () => {
		const showCoupons = document.getElementById("divShowCouponCodes");
		const enterCodeBtn = document.getElementById("btnEnterCouponCode");
		if (showCoupons.classList.contains("hide") && enterCodeBtn) enterCodeBtn.classList.remove("hide");
		else if(enterCodeBtn) enterCodeBtn.classList.add("hide");
	};
	
	const updateHeaderCartSummary = (data) => {
		const headerCartSummary = document.querySelector("#userSpecificLinksBox .cartSummaryContainer");
		if (headerCartSummary && data.PartialView1) {
			headerCartSummary.innerHTML = data.PartialView1;
			if(header4i){
				const headerLinks = new header4i();
				headerLinks.HeaderCart.Init();
			}
		}
	};
	
	const logRemoveItem = (product) => {
    		if (!s) console.error('s not available. Using fallback');
		let account = (s && typeof s.account === 'string') ? s.account : '4imprintprod';
		let z = typeof s_gi === 'function' ? s_gi(account) : null;
	
           	if (z) {
        		z.linkTrackVars = 'products,events';
        		z.linkTrackEvents = 'scRemove';
        		z.events = 'scRemove';
        		z.products = product;
        		z.tl(true, 'o', 'Cart Delete Item');
            	} else {
            		console.warn('Adobe Analytics object is not initialized. Event tracking skipped.');
            	}
    	};
    	
    	const logCartRemoval = (product, callBack) => {
		if (typeof dataLayer !== 'undefined' && !!window.ga && product) {
			let products = [];
			products.push(getProductObject(product));

			// Measure the removal of a product from a shopping cart.
			dataLayer.push({
				'event': 'removeFromCart',
				'ecommerce': {
					'remove': {
						'products': products
					}
				},
				'eventCallback': function() {
					if (callBack) callBack();
				}
			});
		} else {
			if (callBack) callBack();
		}
	};
	
	const getProductObject = (product) => {
		return {
			'name': product.getAttribute('data-description').replace("'", ''),
			'id': product.getAttribute("data-catalog"),
			'price': product.getAttribute("data-unit-price"),
			'brand': product.getAttribute('data-brand'),
			'category': product.getAttribute('data-category'),
			'variant': product.getAttribute('data-color'),
			'quantity': product.getAttribute("data-quantity")

		};
	};

	return{
		Token: token,
		CartID: cartId, 
		Messages: jsMessages, 
		CheckCartHasCoupon: checkCartHasCoupon, 
		UpdateHeaderCartSummary: updateHeaderCartSummary,
		LogRemoveItem: logRemoveItem,
		LogCartRemoval: logCartRemoval
	};
}