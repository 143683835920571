const deleteItemDialog4i = function(){
	const deleteDialog = new Dialog4i();
	const helpers = new formHelpers4i();
	const common = new CartCommon();
	const messages = common.Messages;
	let itemName, deleteCartItemId, trigger;
	
	const dialogContent = 
		'<div class="displayFlex flexJustifyEnd flexAlignItemsCenter">' +
			'<button class="close4iDialog btnToHyp underline text16">Cancel</button>' +
			'<button id="btnConfirmDelete" class="button-md bkgdBlue text16">Delete</button>' +
		'</div>';
		
	const init = () =>{
		deleteDialog.Init({
			triggerElements: [].slice.call(document.querySelectorAll(".cartDeleteBtn")),
			dialogClass: "smallDialog", 
			title: "Delete Product?", 
			content: dialogContent, 
			onOpen: open,
			onClose: close
		});
	};
	
	const open = (el) =>{
		trigger = el;
		helpers.ClearStatus();
		itemName = el.parentNode.parentNode.querySelector(".productInfo").getAttribute("data-description");
		const dialogHeader = document.getElementById("popUpDialog").querySelector('h2');
		
		dialogHeader.innerHTML = "Delete " + itemName + "?";
		deleteCartItemId = el.getAttribute("data-cart-item-id");
		document.getElementById("btnConfirmDelete").addEventListener("click", deleteItem);
	};
	
	const close = () =>{
		deleteCartItemId = undefined;
	};
	
	const deleteItem = async (e) => {
		if(e && e.preventDefault) e.preventDefault();
		const cartItemId = deleteCartItemId;
		helpers.ShowLoadingOverlay(document.getElementById("mainContent"));
		deleteCartItemId = undefined;
		deleteDialog.Close();
			
		let responseJSON;
		try{
			if(common.Token === '') throw new Error("common.Token was empty on cart item delete.");
			const response = await fetch('/cart/deleteitem', { 
				method: 'POST', 
				body: JSON.stringify({ cartId: common.CartID, cartItemId: cartItemId }), 
				headers: { 'Content-Type': 'application/json',  'X-Requested-With': 'fetch' },
				credentials: 'same-origin'
			});
			if(!response.ok) throw new Error(`Error processing delete cart item request: ${response.statusText}`);
			responseJSON = await response.json();
		}catch(error){
			console.error(error);
			showDeleteError();
		}
		
		if(responseJSON){
			if (responseJSON.Success) deleteItemSuccess(cartItemId, responseJSON);
			else showDeleteError();
		}
	};
	
	const showDeleteError = () => {
		helpers.ShowErrorStatus(trigger.parentNode.parentNode, messages.deleteFailed);
		helpers.RemoveLoadingOverlay();
	};
	
	const deleteItemSuccess = (itemID, response) => {
		common.LogRemoveItem(removeItem(itemID, response));
		helpers.ClearStatus();
		if (document.getElementById("itemRemovalAlert")) document.getElementById("itemRemovalAlert").remove();
		//notify screen readers that item has been deleted
		document.querySelector("h1").insertAdjacentHTML("afterend", '<div id="itemRemovalAlert" class="visually-hidden" role="alert" aria-atomic="true"></div>');
		document.getElementById("itemRemovalAlert").textContent = itemName + " removed from your cart.";
		document.getElementById("cartLinks").getElementsByTagName("a")[0].focus();
		helpers.RemoveLoadingOverlay();
	};

	
	const checkCouponRemoved = (data) => {
		if(document.getElementById("removeCouponCode")){
			const code = document.getElementById("removeCouponCode").getAttribute("data-coupon-code");
			// remove dollar and cents symbol
			if (code && parseFloat(data.CouponTotal.replace(/(&#[0-9]+;)|(&[A-Za-z0-9]+;)|,/g, "")) === 0)
				deleteCouponCode(data, code);
		}
	};
	
	const deleteCouponCode = (data, code) => {
		if (!data.ItemCount) {
			location.reload();
		} else {
			const cartSubtotal = document.getElementById("cartSub");
			cartSubtotal.textContent = "";
			const couponCodeDiv = document.getElementById("divShowCouponCodes");
			couponCodeDiv.classList.add("hide");
			const removeCodeBtn = document.getElementById("removeCouponCode");
			removeCodeBtn.setAttribute("title", document.getElementById("removeCouponCode").getAttribute("title").replace(code, ""));
			removeCodeBtn.setAttribute("data-coupon-code", "");
			const appliedCode = document.getElementById("couponApplied");
			appliedCode.innerHTML = document.getElementById("couponApplied").innerHTML.replace(code, "");
			
			const cartItemCounts = document.querySelectorAll(".cartItemCount");
			for (let i = 0; i < cartItemCounts.length; i++) {
				cartItemCounts[i].innerHTML = data.ItemCount;
			}
			
			cartSubtotal.innerHTML = data.SubTotal;
			const discount = document.getElementById("couponDiscountAmount");
			discount.innerHTML = data.CouponTotal;
			const checkoutBtn = document.getElementById("orderSummaryBox").getElementsByClassName("btnCheckOut")[0];
			checkoutBtn.focus();
			common.CheckCartHasCoupon();
			common.UpdateHeaderCartSummary(data);
		}
	};
	
	const removeItem = (cartItemId, data) => {
		const element = document.getElementById("h3ProductInfo_" + cartItemId);

		if (!data.ItemCount) {
			common.LogCartRemoval(element, location.reload(true));
		} else {
			const product = ";" + element.getAttribute("data-catalog") + " " + element.getAttribute("data-description").replace("\"", "") + ";" + element.getAttribute("data-quantity") + ";" + element.getAttribute("data-price");

			document.getElementById("divCartItem_" + cartItemId).remove();
			const cartItemCounts = document.querySelectorAll(".cartItemCount");
			for (let i = 0; i < cartItemCounts.length; i++) {
				cartItemCounts[i].innerHTML = data.ItemCount;
			}
			const cartsub = document.getElementsByClassName("cartSubtotal");
			for (let x = 0; x < cartsub.length; x++) {
				cartsub[x].innerHTML = data.SubTotal;
			}

			document.getElementById("couponDiscountAmount").innerHTML = data.CouponTotal;
			common.UpdateHeaderCartSummary(data);
			document.getElementById("divItemNotifications").innerHTML = data.PartialView2;
			checkCouponRemoved(data);
			helpers.RemoveLoadingOverlay();
			common.LogCartRemoval(element);

			return product;
		}
	};
	
	return{
		Init: init
	};
};