const cartPage = function() {
	const common = new CartCommon();
	const helpers = new formHelpers4i();
	
	const init = () => {
		const isEmptyCart = document.getElementById("divHotScroller");
		if (isEmptyCart) {
			const productScroller = new prodScroller();
			productScroller.Init("divHotScroller", 5, 3, 2); //numbers are # items shown per breakpoint
			const starRatings = new StarRating4i();
			starRatings.Init();
		} else {			
			common.CheckCartHasCoupon();
			
			const cartCoupon = new CouponCode4i();
			cartCoupon.Init();
			
			const cartAccordions = new getAccordions4i();
			cartAccordions.init(document.getElementById("mainContent"));
			
			const deleteItemDialog = new deleteItemDialog4i();
			deleteItemDialog.Init();
			
			const detailsDialog = new detailsDialog4i();
			detailsDialog.Init();
			
			document.addEventListener("click", cartEvents);	
		}

		if (!!window.performance && window.performance.navigation.type === 2) {
			// page has been hit using back or forward buttons
			document.getElementById("bodyPanel").style.display = "none";
			window.location.reload();
		}
	};
	
	const cartEvents = (e) =>{
		if(e.target.id == "btnCheckOut_mini" || e.target.id == "btnCheckOut_large"){
			helpers.ShowLoadingOverlay(document.getElementById("mainContent"));
		}
	};

	return {
		Init: init
	};
};

(async () => {
	const cart4i = new cartPage();
	cart4i.Init();
})();