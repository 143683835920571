const detailsDialog4i = function(){
	const common = new CartCommon();
	const messages = common.Messages;
	const helpers = new formHelpers4i();
	const detailsDialog = new Dialog4i();
	let prodName, dialog, dialogHead, dialogContent, trigger;
	
	const init = () =>{
		detailsDialog.Init({
			triggerElements: [].slice.call(document.querySelectorAll('.cartDetailsBtn')),
			dialogClass: "divItemDetails smallDialog",
			title: "Loading Item Details", 
			content: helpers.LoadingIcon, 
			onOpen: open
		});
	};
	
	const open = (el) => {
		helpers.ClearStatus();
		trigger = el;
		const itemID = trigger.id.replace("btnItemDetails_", "");
		prodName = trigger.parentNode.parentNode.querySelector(".productInfo").textContent;
		dialog = document.getElementById("popUpDialog");
		if(dialog){
			dialogHead = dialog.querySelector("h2");
			dialogContent = dialog.querySelector(".dialogContent");
		}
		//console.log(itemID);
		getDetails(itemID);
	};
	const getDetails = async (itemID) => {
		let responseHTML;
		try{
			const response = await fetch('/cart/itemdetails', { 
				method: 'POST', 
				body: JSON.stringify({ cartId: common.CartID, cartItemId: itemID }), 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			if(!response.ok) throw new Error(`Error processing cart item details request: ${response.statusText}`);
			responseHTML = await response.text();
		}catch(error){
			console.error(error);
			showError();
		}
		if(responseHTML && responseHTML.length > 0) fillDialog(responseHTML);
		else showError();
	};
	const showError = () => {
		detailsDialog.Close();
		helpers.ShowErrorStatus(trigger.parentNode, messages.getItemDetailsError);
	};
	const fillDialog = (itemDetails) => {
		dialogHead.textContent = prodName;
		dialogContent.innerHTML = itemDetails;
	};
	
	return{
		Init: init
	};
};