const CouponCode4i = function(){
	const common = new CartCommon();
	const messages = common.Messages;
	const helpers = new formHelpers4i();
	const openFormBtn = document.getElementById("btnEnterCouponCode");
	const couponDiv = document.getElementById("divCouponCode");
	const mainContent = document.getElementById("mainContent");
	
	let deleteCouponAbort = new AbortController();
	let addCouponAbort = new AbortController();
	let signal, couponInput, couponError;
	
	const init = () =>{
		document.addEventListener("click", couponEvents);
	};
	
	const couponEvents = (e) =>{
		if(e.target.id == "btnApplyCouponCode"){
			e.preventDefault();
			validateCoupon(e);
		}else if(e.target.id == "btnCancelCouponCode"){
			e.preventDefault();
			hideCouponForm();
		}else if(e.target.id == "removeCouponCode"){
			e.preventDefault();
			e.target.disabled = true; 
			submitDelete();
		}else if(e.target.id == "btnEnterCouponCode"){
			e.preventDefault();
			showCouponForm();
		}	
	};

	const showCouponForm = () => {
		helpers.ClearStatus();
		openFormBtn.setAttribute("aria-expanded", "true");
		openFormBtn.classList.add("hide");
		couponDiv.classList.remove("hide");
		couponInput = document.getElementById("txtCouponCode");
		couponInput.focus();
	};
				
	const validateCoupon = (e) => {
		helpers.ShowBusyBtn(e.currentTarget);
		helpers.ClearStatus();
		const couponCode = couponInput.value.toUpperCase().trim();
		if (couponCode) submitCoupon(couponCode);
		else showError("Oops! The coupon code is not valid. Please Try Again.");
	};
				
	const showError = (errMsg) => {
		helpers.ClearBusyBtn();
		couponError = document.getElementById("couponCodeError");
		couponError.classList.remove("hide");
		couponError.textContent = errMsg;
		couponInput.classList.add("input-validation-error");
		couponInput.setAttribute("aria-invalid", "true");
		couponInput.focus();
	};
	
	const hideCouponForm = () => {
		helpers.ClearStatus();
		openFormBtn.setAttribute("aria-expanded", "false");
		openFormBtn.classList.remove("hide");
		openFormBtn.focus();
		couponDiv.classList.add("hide");
		if(couponError) couponError.classList.add("hide");
		couponInput.classList.remove("input-validation-error");
		couponInput.removeAttribute("aria-invalid");
		couponInput.value = "";
	};
	
	const submitCoupon = async (code) => {
		// if a coupon code already exists, we need to remove it before adding a new one
		// this happens during a mass coupon code third party app
		if(!document.getElementById("divShowCouponCodes").classList.contains("hide")) await submitDelete();
		
		helpers.ShowLoadingOverlay(mainContent);
		helpers.ShowBusyBtn(document.getElementById("btnApplyCouponCode"));
		
		if(addCouponAbort) addCouponAbort.abort();
             	addCouponAbort = new AbortController(); 
		signal = addCouponAbort.signal;
		
		let responseJSON;	        
		try{
			const response = await fetch('/cart/addcoupon', { 
				method: 'POST', 
				body: JSON.stringify({ cartId: common.CartID, couponCode: code }), 
				signal: signal,
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "RequestVerificationToken": common.Token }
			});
			if(!response.ok) {
				const errorText = await response.text();
                		// Check if the response is an HTML document indicating an access issue
                		if (errorText.includes("<html"))
                    			throw new Error("Access denied: Unable to process cart delete coupon code request due to access restrictions set by cloudflare.");
                		else
                			throw new Error(`Error processing cart add coupon code request: ${response.statusText}, ${errorText}`);
			}
			responseJSON = await response.json();
		}catch(error){
			showAddError(messages.couponError);
			console.log(error);
		}
		
		if(responseJSON){
			if (responseJSON.Success) addCouponSuccess(responseJSON, code);
			else showAddError(responseJSON.Message);
		}
	};
	
	const showAddError = (msg) => {
		showError(msg);
		helpers.RemoveLoadingOverlay();
	};
	
	const addCouponSuccess = (data, code) =>{
		if (!data.ItemCount) {
			location.reload(true);
		} else {
			helpers.ClearBusyBtn();
			hideCouponForm();
			const cartSub = document.getElementById("cartSub");
			const removeCodeBtn = document.getElementById("removeCouponCode");
			const successDiv = document.getElementById("couponApplied");
			
			cartSub.textContent = "";
			removeCodeBtn.setAttribute("title", removeCodeBtn.getAttribute("title") + code);
			removeCodeBtn.setAttribute("data-coupon-code", code);
			successDiv.innerHTML = code + successDiv.innerHTML;
			
			const cartItemCounts = document.querySelectorAll(".cartItemCount");
			for (let i = 0; i < cartItemCounts.length; i++) {
				cartItemCounts[i].innerHTML = data.ItemCount;
			}
			
			cartSub.innerHTML = data.SubTotal;
			const discountAmount = document.getElementById("couponDiscountAmount");
			const couponsDiv = document.getElementById("divShowCouponCodes");
			discountAmount.innerHTML = data.CouponTotal;
			couponsDiv.classList.remove("hide");
			removeCodeBtn.focus();
			common.CheckCartHasCoupon();
			common.UpdateHeaderCartSummary(data);
		}
		logAddCouponCode(code);
		helpers.RemoveLoadingOverlay();
	};
	
	const submitDelete = async () => {
		helpers.ClearStatus();
		helpers.ShowLoadingOverlay(mainContent);
		
		// Create a new controller for the new request
		if(deleteCouponAbort) deleteCouponAbort.abort();
             	deleteCouponAbort = new AbortController(); 
		signal = deleteCouponAbort.signal;
		
		const code = document.getElementById("removeCouponCode").getAttribute("data-coupon-code");

		let responseJSON;
		try{
			const response = await fetch('/cart/deletecoupon', { 
				method: 'POST', 
				body: JSON.stringify({ cartId: common.CartID, couponCode: code }), 
				signal: signal,
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "RequestVerificationToken": common.Token }
			});
			if(!response.ok) {
				const errorText = await response.text();
                		// Check if the response is an HTML document indicating an access issue
                		if (errorText.includes("<html"))
                    			throw new Error("Access denied: Unable to process cart delete coupon code request due to access restrictions set by cloudflare.");
                		else
                			throw new Error(`Error processing cart remove coupon code request: ${response.statusText}, ${errorText}`);
			}
			responseJSON = await response.json();
		}catch(error){
			showDeleteError(messages.deleteCouponError);
			console.log(error);
		}
		
		document.getElementById("removeCouponCode").disabled = false;
		
		if(responseJSON){
			if (responseJSON.Success) deleteCouponSuccess(responseJSON, code);
			else showDeleteError(responseJSON.Message);
		}
	};
	
	const showDeleteError = (msg) => {
		const couponDivFirstElement = document.getElementById("divShowCouponCodes").firstElementChild;
		helpers.ShowErrorStatus(couponDivFirstElement, msg);
		helpers.RemoveLoadingOverlay();
	};
	
	const deleteCouponSuccess = (data, code) => {
		if (!data.ItemCount) {
			location.reload(true);
		} else {
			helpers.ClearBusyBtn();
			const cartSub = document.getElementById("cartSub");
			const removeCodeBtn = document.getElementById("removeCouponCode");
			const successDiv = document.getElementById("couponApplied");
			cartSub.textContent = "";
			document.getElementById("divShowCouponCodes").classList.add("hide");
			removeCodeBtn.setAttribute("title", removeCodeBtn.getAttribute("title").replace(code, ""));
			removeCodeBtn.setAttribute("data-coupon-code", "");
			successDiv.innerHTML = successDiv.innerHTML.replace(code, "");
			
			const cartItemCounts = document.querySelectorAll(".cartItemCount");
			for (let i = 0; i < cartItemCounts.length; i++) {
				cartItemCounts[i].innerHTML = data.ItemCount;
			}
			
			cartSub.innerHTML = data.SubTotal;
			const discountAmount = document.getElementById("couponDiscountAmount");
			const checkoutBtn = document.getElementById("orderSummaryBox").getElementsByClassName("btnCheckOut")[0];
			discountAmount.innerHTML = data.CouponTotal;
			checkoutBtn.focus();
			common.CheckCartHasCoupon();
			common.UpdateHeaderCartSummary(data);
			helpers.RemoveLoadingOverlay();
		}
	};
	
	const logAddCouponCode = (couponCode) => {
	      	if (!s) console.error('s not available. Using fallback');
       	let account = (s && typeof s.account === 'string') ? s.account : '4imprintprod';
       	
       	//initialize the Adobe Analytics object z using s_gi(account) 
    		let z = typeof s_gi === 'function' ? s_gi(account) : null;

            	if (z) {
                	z.linkTrackVars = 'eVar5,events';
                	z.linkTrackEvents = 'event2';
                	z.eVar5 = couponCode;
                	z.events = 'event2';
                	z.tl(true, 'o', 'Coupon Codes');
            	} else {
                	console.warn('Adobe Analytics object is not initialized. Event tracking skipped.');
            	}
        };
				
	return{
		Init: init
	};
}